import React, { AllHTMLAttributes, ReactNode, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './services.module.scss'
import ServiceTab from './ServiceTab'

export interface ServiceItem {
  name: string
  title: string
  subtitle: string
}

interface ServiceProps extends Omit<AllHTMLAttributes<ReactNode>, 'data'> {
  data: {
    contentTypeAlias: string
    title: string
    subtitle: string
    itemsIncludedServices: ServiceItem[]
    illustration: {
      src: string
    }
  },
  layout?: string;
}

export default function Services(props: ServiceProps) {
  const {
    data: {
      title,
      subtitle,
      itemsIncludedServices = [],
      illustration,
      contentTypeAlias,
    },
    className = '',
    layout = 'default',
  } = props
  const { t } = useTranslation(['residence'])

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const handleGoToPaid = () => {
    const $paidService = document.getElementById('blockPaidServices')
    window.scrollTo({
      top: $paidService?.offsetTop,
      behavior: 'smooth',
    })
  }

  const breakpoint = useBreakpoint()

  return (
    <section
      ref={ref}
      id={contentTypeAlias}
      className={`block-service ${styles.services} ${className} animate ${
        inView ? 'in-view' : ''
      } layout--${layout}`}
    >
      <div className="container lg:grid lg:gap-x-4 grid-cols-12">
        <div className={`lg:col-span-4 ${styles.servicesDesc}`}>
          <Text tag="h2" as="h2" className="services-title">
            {title}
          </Text>
          { layout === 'landing' && subtitle && (
              <Text
                tag="p"
                as="subtitleH2"
                dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
              ></Text>
          )}
          <KLink
            label={t('Voir les services a la carte', 'residence') as string}
            btnType="dark"
            tag="button"
            onClick={handleGoToPaid}
          />
          {!['sm', 'md'].includes(breakpoint) && (
            <div className="flex justify-end">
              {illustration.src && <img src={illustration.src} alt="" />}
            </div>
          )}
        </div>
        {itemsIncludedServices && (
          <div className="lg:col-span-8">
            <ServiceTab data={itemsIncludedServices}/>
          </div>
        )}
      </div>
    </section>
  )
}
