import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Space from '../components/Shared/Space'
import Event from '../components/Shared/Event'
import Summer from '../components/Shared/Summer'
import Form from '../components/Shared/Form'
import FormResidenceMultiple from '../components/Shared/FormResidenceMultiple'
import Logement from '../components/Shared/Logement'
import Location from '../components/Shared/Location'
import Services from '../components/Shared/Services'
import CartService from '../components/Shared/CartService'
import Questionnaires from '../components/Shared/Questionnaires'
import * as styles from './styles/residence.module.scss'
import { Helmet } from 'react-helmet'
import Instagram from '../components/Shared/Instagram'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import Meta from '../components/Core/Meta'
import { useLocation } from '@reach/router'
import AnchorNav from '../components/Core/AnchorNav'
import { useTranslation } from '../hooks/useTranslation'
import BlockSeoText from '../components/Shared/BlockSeoText'
import Breadcrumb from '../components/Core/Breadcrumb'
import slugify from 'slugify'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import Button from "../components/Core/Button";
import {
  selectSfResidencesList,
  sfResidencesInit,
} from '../state/salesforceResidences'
import api from '../utils/api'

export const Head = head('allUmbracoLandingResidenceMultiple', 'residence-multiple')

export default function ResidenceMultiple(props: any) {
  const dispatch = useDispatch()
  const { data } = props
  const page = data.allUmbracoLandingResidenceMultiple.nodes[0]
  const instagramData = data.umbracoInstagram
  const sfResidences: any[] = useSelector(selectSfResidencesList)

  useEffect(() => {
    const getSalesforceData = async () => {
      const salesforceData = await api.getSalesforces()
      if (salesforceData) {
        const salesforceResidences = salesforceData['ResidencesColiving']
        if (salesforceResidences && salesforceResidences.values) {
          return salesforceResidences.values
        }
      }
    }
    if (sfResidences && sfResidences.length > 0) {
      setSalesforceResidences(sfResidences)
    } else {
      getSalesforceData()
        .then((residences) => {
          if (residences) {
            setSalesforceResidences(residences)
          }
        })
        .catch((e) => console.log('Error getting salesforce data', e))
    }
  }, [])

  const setSalesforceResidences = (residences: any[]) => {
    const filteredResidencesWithNameAndId = residences.filter(
      (residence: {
        Id: string
        Name: string
        FilialeCode__c: string
        AD_ResidenceId__c: string
        Residence__c: string
        ResidenceLabel: string
      }) => residence.Id && residence.Name
    )
    const groupBy = function (xs: any, key: string) {
      return xs.reduce(function (rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    }
   
    const groupedLogements = groupBy(filteredResidencesWithNameAndId, 'Id')
    dispatch(
      sfResidencesInit(Object.values(groupedLogements).map((item) => item[0]))
    )
  }

  const handleGoToInfo = () => {
    const $info = document.getElementById('landing-form')
    window.scrollTo({
      top: $info?.offsetTop,
      behavior: 'smooth',
    })
  }

  console.log('--page--', page)

  return (
    <>
     <Helmet>
        <body className="single-residence" />
      </Helmet>
      <div className={`${styles.residenceCta} container fixed left-0 right-0 text-right top-4`}>
        <Button
            type="anchor"
            btnType="light"
            onClick={handleGoToInfo}
        >
          {page.ctaTitle}
        </Button>
      </div>
      <Layout className={`landing-residence landing-residence-mutliple ${styles.residenceDetail}`}>
        <Hero
            title={page.title.replace(/\n/g, '<br>')}
            image={
              page.imageCover && page.imageCover.childImageSharp
                ? page.imageCover
                : page.imageCover && page.imageCover.media
                ? page.imageCover.media
                : undefined
            }
            image-mobile={page.imageCoverMobile}
            video={page.videoCover}
            customClassname="hero__landing_residence"
          >
            <DecoBigIllus className="deco" />
        </Hero>
       
        {page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'blockFormResidenceMutlipleDetails':
                  return (
                    <FormResidenceMultiple data={page} formInfo={blockContent}/>
                  )
              case 'residencePush':
                return (
                  <>
                    <Summer
                      data={{
                        contentTypeAlias: blockContent.contentTypeAlias,
                        title: blockContent.title,
                        paragraph: blockContent.subtitle,
                        link:
                        blockContent.links && blockContent.links[0]
                          ? blockContent.links[0]
                          : {
                              name: 'Contacter la residence',
                              url: '/',
                            },
                        position: page.title,
                      }}
                      illustration={blockContent.illustration}
                      residence={page}
                      blockClassName={`summer-push landing-single ${
                        index === 0 ? 'concept-push' : ''
                      }`}
                      bg={blockContent.backgroundColor}
                      titleColor={blockContent.titleColor}
                      key={`sp-${index}`}
                      formClass="landing-form"
                    />
                      <Form data={data.umbracoLandingResidencePage} />
                  </>
                )
              case 'blockAccomodations':
                return (
                  <Logement
                    data={blockContent}
                    key={`r-${index}`}
                    residence={page}
                    className="!pt-0"
                  />
                )
              case 'blockIncludedServices':
                return (
                  <Services
                    data={blockContent}
                    key={`r-${index}`}
                    layout="landing"
                  />
                ) 
              case 'blockConcept1':
                return (
                  <Space
                    data={blockContent}
                    key={`s-${index}`}
                    className="space"
                    layout={'landing'}
                  />
                )   
              case 'blockPaidServices':
                return (
                  <CartService
                    data={blockContent}
                    key={`cs-${index}`}
                    layout={'landing'}
                  />
                )
              case 'blockNewEvents':
                return <Event data={blockContent} key={`be-${index}`} />  
              case 'blockFAQ':
                return (
                  <Questionnaires
                    data={blockContent}
                    key={`bf-${index}`}
                    layout={'landing'}
                  />
                )
              }
            }
            return null
          })}
           <Instagram data={instagramData} hidden={true} />
      </Layout>
    </>
  )
}

export const query = graphql`
 query ($lang: String!) {
  allUmbracoLandingResidenceMultiple (filter: {lang: {eq: $lang}}) {
    nodes {
      name
      slug
      title
      metaTitle
      metaFollow
      metaIndex
      step1CTA
      step1Title
      step2Cta
      step2Title
      step3Cta
      step3Title
      step3Description
      imageCover{
        media {
          alt
          name
          _url
        }
      }
      ctaTitle
      linkedResidences {
        _id
        title
        salesforceId
      }
      items {
        content {
          title
          sectionTitle
          subtitle
          serviceCta
          contentTypeAlias
          formCta
          cTATitle
          description
          illustration {
            src
          }
          image {
            media {
              _url
              alt
            }
          }
          link {
            url
            name
            type
          }
          itemsPaidServices {
            name
            title
            subtitle
            shortName
            contentTypeAlias
            thumbnail {
              src
              gatsbyImageData(width: 1100, quality: 90)
            }
          }
          itemsIncludedServices {
            name
            title
            subtitle
            contentTypeAlias
            cTATitle
            ctaLink {
              udi
              url
              name
              type
            }
          }
          itemsFaq {
            name
            answer
            question
            contentTypeAlias
          }
          newItemsAccomodation {
            content {
              title
              subtitle
              priceFrom
              priceFromValue
              roomSize
              contentTypeAlias
              shortDescription
              accomodationTag
              accomodationType {
                accomodationTypeTitle
              }
              thumbnailImages {
                gatsbyImageData(width: 1560, quality: 90)
                media {
                  alt
                  name
                  _url
                }
              }
              details {
                title
                boTitle
                subtitle
              }
              accommodationDetailModal {
                content {
                  title
                  description
                }
              }
              accommodationDetailModalDescription
              hideOnHifeWebsite
            }
          }
          eventsList {
                title
                subtitle
                bg
                backgroundVimeoId
                price
                thumbnail {
                  media {
                    alt
                    _url
                  }
                  gatsbyImageData(width: 880, quality: 90)
                }
          }
          compareAccomodations {
            content {
              title
              tag
              surface
              servicesAndOptions
              plus
              capacity
              bed
              thumbnail {
                src
              }
            }
          }
          linkFaq {
            url
            name
            type
          }
          spaces {
            title
            subtitle
            name
            mainColor
            ctaTitle
            ctaLink {
              udi
              url
              name
              type
            }
            logo {
              src
            }
            medias {
              src
            }
          }
          illustration {
            src
          }
          linkFaq {
            name
            url
          }
        }
      }
    }
  }
  umbracoInstagram{
    title
    hashtag
    instagramHandle
    instagramLinkButton {
      url
      name
      type
    }
    enabled
  }
  allPageIntl {
    edges {
      node {
        name
        uri
        alternates {
          name
          uri
          lang
        }
      }
    }
  }
}
`
